import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "components/Layout"
import HeroLego from "legos/HeroLego"
import CategoriesDisplay from "components/CategoriesDisplay"
import BlogPostGrid from "components/BlogPostsGrid"
import Button from "components/Button"
import theme from "theme"

const BlogHomepage = ({
  data: {
    postData: { posts },
    blog: { hero, postsPerPage },
  },
  location,
}) => {
  const [activeCategory, setActiveCategory] = useState(
    location?.state?.category
  )
  const [postLimit, setPostLimit] = useState(postsPerPage)

  // Build categories from post data to avoid showing categories with no posts
  const categories = Array.from(
    new Set(posts.flatMap(post => post.categories.map(({ name }) => name)))
  ).map(name => ({ name }))

  const activePosts = activeCategory
    ? posts.filter(post =>
        post.categories.find(({ name }) => name === activeCategory)
      )
    : posts

  return (
    <Layout title="Blog">
      <HeroLego {...hero} />

      <div
        css={{
          background: theme.gray1,
          position: "relative",
          zIndex: 2,
          paddingBottom: 30,
        }}
      >
        <div
          css={{
            padding: "60px 0",
            textAlign: "center",
            background: "inherit",
            position: "relative",
            zIndex: 2,
            [theme.mobile]: { padding: "40px 0" },
          }}
        >
          <h2 css={theme.h2}>Categories</h2>

          <CategoriesDisplay
            categories={categories}
            activeCategory={activeCategory}
            onClick={setActiveCategory}
            css={{ marginTop: 50, [theme.mobile]: { marginTop: 20 } }}
          />
        </div>

        <BlogPostGrid
          posts={activePosts.slice(0, postLimit)}
          cta={
            activePosts.length > postLimit ? (
              <Button
                onClick={() => {
                  setPostLimit(prev => prev + postsPerPage)
                }}
              >
                View More
              </Button>
            ) : null
          }
        />
      </div>
    </Layout>
  )
}

export default BlogHomepage

BlogHomepage.propTypes = {
  data: PropTypes.shape({
    postData: PropTypes.shape({
      posts: PropTypes.array.isRequired,
    }).isRequired,
    blog: PropTypes.shape({
      hero: PropTypes.shape(HeroLego.propTypes).isRequired,
      postsPerPage: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      category: PropTypes.string,
    }),
  }),
}

export const query = graphql`
  {
    postData: allSanityBlogPost(sort: { fields: date, order: DESC }) {
      posts: nodes {
        date
        title
        slug {
          current
        }
        cardImage {
          ...ImageWithPreview
        }
        categories {
          name
        }
      }
    }

    blog: sanityBlogSettings {
      hero {
        ...HeroLego
      }
      postsPerPage
      metadata {
        ...Metadata
      }
    }
  }
`

import React from "react"
import PropTypes from "prop-types"
import Button from "components/Button"
import theme from "theme"

const CategoriesDisplay = ({
  categories,
  activeCategory,
  onClick,
  className,
}) => (
  <div
    css={{
      overflowX: "auto",
      maxWidth: "100%",
      paddingLeft: 20,
      WebkitOverflowScrolling: "touch",
      scrollbarWidth: "none",
      userSelect: "none",
      "::-webkit-scrollbar": {
        display: "none",
        width: 0,
        height: 0,
        background: "transparent",
      },
      [theme.max(600)]: {
        paddingLeft: "max(20px, calc((100vw - 450px) / 2))",
      },
    }}
    className={className}
  >
    <div
      css={{
        display: "flex",
        margin: "0 auto",
        width: "max-content",
        ":after": {
          content: "''",
          display: "block",
          width: 20,
          [theme.max(600)]: {
            width: "max(20px, calc((100vw - 450px) / 2))",
          },
        },
      }}
    >
      {categories.map(({ name }) => {
        const buttonProps = onClick
          ? { onClick: () => onClick(prev => (prev === name ? null : name)) }
          : { href: "/blog/", state: { category: name } }

        return (
          <CategoryButton
            key={name}
            name={name}
            active={name === activeCategory}
            css={{ marginRight: 10, ":last-of-type": { marginRight: 0 } }}
            {...buttonProps}
          />
        )
      })}
    </div>
  </div>
)

export default CategoriesDisplay

CategoriesDisplay.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  activeCategory: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

const CategoryButton = ({ name, active, ...rest }) => (
  <Button
    css={{
      ...theme.h5,
      flex: "0 0 auto",
      padding: "9px 12px",
      background: active ? theme.stampRed : theme.greyBrown,
      [theme.mobile]: {
        padding: "9px 12px", // override default Button mobile styles
      },
    }}
    {...rest}
  >
    {name}
  </Button>
)

CategoryButton.propTypes = {
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
}
